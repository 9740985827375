import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = props => (
  <Layout>
    <div className="index">
      <SEO title="Home" keywords={['sewing', 'knitting', 'crafting']} />
      <div className="index__coming-soon">
        <h1>Coming Soon!</h1>
      </div>
      <Img
        className="index__logo"
        fluid={props.data.logoImg.childImageSharp.fluid}
      />
    </div>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    logoImg: file(relativePath: { eq: "B&SLogoMed.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
